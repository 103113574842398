<template lang='pug'>
    //- Post使用
    div
        vue-editor.mr-2.ml-1(ref='quillEditor' :class="ispost?'editPostCss':'editReplyCss'" v-model='editContent' :editorOptions='ispost? editorSettings:editorSettings_no_tool' )
        v-row(:class='attachments.length == 0 ? "":"mt-2"')
            v-col.pl-0.pr-0(cols='1')
                emoji-picker(@emoji="insert" :search="search" )
                    div(slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent")
                        v-btn(type="button" icon )
                            v-icon mdi-emoticon-happy-outline
                    div(slot="emoji-picker" slot-scope="{ emojis, insert }")
                        div(class="emoji-picker")
                            div(class="emoji-picker__search")
                                input(type="text" v-model="search")
                            div
                                div(v-for="(emojiGroup, category) in emojis" :key="category")
                                    h5 {{ category }}
                                    div(class="emojis")
                                        span(v-for="(emoji, emojiName) in emojiGroup"
                                            :key="emojiName"
                                            @click="insert(emoji)"
                                            :title="emojiName") {{ emoji }}
            v-col.pl-0(v-if='attachments.length == 0 && ispost' cols='1' style="margin-top:-2px;")
                v-file-input(:label= '$t("CAMPAIGN_DETAIL.ATTACHMENT")' placeholder='' hide-input  dense multiple :rules="formRules.fileRule" v-model='attachments' @change='onChangeFile' v-if='attachments') 
            //- v-col.pl-0(v-else cols='5' style="margin-left:-20px;margin-top:-2px;")
            v-col.pl-0(v-if='attachments.length > 0 && ispost' cols='5' style="margin-left:-20px;margin-top:-2px;hegiht:45px;")
                v-file-input.pa-0( :label= '$t("CAMPAIGN_DETAIL.ATTACHMENT")' placeholder='123'  dense multiple :rules="formRules.fileRule" v-model='attachments' @change='onChangeFile' v-if='attachments')
        v-row(v-if='ispost' :style="attachments.length == 0 ? 'margin-top:-60px;':'margin-top:-80px;'" :class='ispost?"pt-0":".pt-0 pb-1"')
            v-col.pt-5(cols='2' style='margin:0 0 0 68%')
                v-btn(:ripple='false' @click='onCancel' width='75' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
            v-col.pt-5(cols='2' style='margin:0 0 0 -10px')
                v-btn(:ripple='false' @click='onConfirm' width='75' height='40' color='success' depressed dark)
                    v-progress-circular(v-if='false' indeterminate size='20')
                    .t-white(v-else) {{$t('INBOX.SEND')}}
        v-row(v-else :style="'margin-top:-60px;'" :class='ispost?"pt-0":".pt-0 pb-1"')
            v-col.pt-5(cols='2' style='margin:0 0 0 65%')
                v-btn(:ripple='false' @click='onCancel' width='75' height='40' color='cancel' depressed dark) {{$t('GENERAL.CANCEL')}}
            v-col.pt-5(cols='2')
                v-btn(:ripple='false' @click='onConfirm' width='75' height='40' color='success' depressed dark)
                    v-progress-circular(v-if='false' indeterminate size='20')
                    .t-white(v-else) {{$t('INBOX.SEND')}}
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' attach=true)
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage' )
</template>



<script>
import { VueEditor } from 'vue2-editor';
import {EmojiPicker} from 'vue-emoji-picker';
import i18n from '/common/plugins/vue-i18n.js' 
import OrganizationDataService from '@/services/OrganizationDataService';
import errorDialog from '@/components/Dialog/errorDialog';

async function getMention(searchTerm){
    let list = []
    await OrganizationDataService.getMentionOwner().then((response)=>{
        let userJson = window.localStorage.getItem('user');
        let userId = JSON.parse(userJson).userid;
        list = response.data.map(item => ({
            id :item.id,
            value : item.fullName = item.last_name + ' ' + item.first_name
        })
        )
        list.unshift({id:'all',value: i18n.t('GENERAL.ALL')})
        list = list.filter(el => el.id != userId)
        list = list.filter(el => el.value.includes(searchTerm))
    })
    return list;
}

export default {
    name: 'ReplyEditor',
    props: {
        ownerinfo:{
            require: false,
        },
        ispost:{
            require: true,
        },
        content:{
            require: true,
        },
    },
    components: {
        VueEditor,
        EmojiPicker,
        errorDialog
    },
    data() {
        return {
            editorSettings_no_tool: {
                modules: {
                    imageResize: true,
                    toolbar:false,
                    // short_name_emoji: true,
                    // toolbar_emoji: true,
                    // textarea_emoji: true,
                    mention:{
                        mentionDenotationChars: ['@'],
                        offsetLeft: 4,
                        source: async function(searchTerm,renderList){
                            const matchedPeople = await getMention(searchTerm);
                            renderList(matchedPeople)
                        },
                        onSelect:(data, insertItem) => { 
                            const item = {
                                text: `@${data.value}`,
                                name: data.value,
                                id: data.id,
                            }
                            insertItem(data) 
                            this.onSelectd(item)
                        },
                    },
                },
            },
            editorSettings: {
                modules: {
                    imageResize: true,
                    toolbar: {
                        container: [
                            [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                            // ['emoji'],
                            ['bold', 'italic', 'underline'],
                            [{ 'align': '' },{ 'align': 'center' },{ 'align': 'right' }],                            
                            [{ 'list': 'ordered'}, { 'list': 'bullet' }/*, { 'list': 'check' }*/],
                            [{ 'color': [] }, { 'background': [] }],
                            ['link', 'image'],
                            ['clean']
                            ],
                        // handlers: {
                        //     emoji: function() {}
                        // }
                    },
                    // short_name_emoji: true,
                    // toolbar_emoji: true,
                    // textarea_emoji: false,
                    mention:{
                        mentionDenotationChars: ['@'],
                        offsetLeft: 4,
                        source: async function(searchTerm,renderList){
                            const matchedPeople = await getMention(searchTerm);
                            renderList(matchedPeople)
                        },
                        onSelect:(data, insertItem) => { 
                            const item = {
                                text: `@${data.value}`,
                                name: data.value,
                                id: data.id,
                            }
                            insertItem(data) 
                            this.onSelectd(item)
                        },
                    },
                },
            },
            formRules: {
                    // fileRule: [files => !files || !files.some(file => file.size > 2_097_152) || 'Attachment should be less than 2 MB!'],
                    fileRule: [files => !files || this.isFileAvailable || i18n.t('RULE.RULE_FILE')],
            },
            isFileAvailable:true,
            attachments:[],
            editorOpen:false,
            editContent:"",
            search:'',
            mentionItems:[],
            passMentionItems:[],
            replyData:{},
            errorDialog:false,
            errorMessage:'',
        };
    },
    created (){
        this.editContent = this.content;
    },
    methods: {
        onEmitErrorDialog() {
            this.errorDialog = false;
        },
        onCancel(){
                this.$emit("onCancel")
        },
        onConfirm(){
            if(!this.isFileAvailable){
                this.errorMessage=i18n.t('INBOX.FILE_LIMIT');
                this.errorDialog=true;
                return;
            }
            if(this.editContent == ""||this.editContent == null){
                this.errorMessage=i18n.t('INBOX.CONTENT_EMPTY');
                this.errorDialog=true;
                this.passMentionItems.length = 0;
                return;
            }
            this.removeNoExistMention();
            let data={
                "content":this.editContent,
                "mentioned":this.passMentionItems
            }
            this.$emit("onConfirm",data,this.attachments)
        },
        onSelectd(item){
            this.mentionItems.push(item);
        },
        insert(emoji) {
            let quill=this.$refs.quillEditor.quill;
            let length = 0;
            try{
                length = quill.getSelection().index;
            }catch{
                length = 0;
            }
            quill.insertEmbed(length,'text',emoji);
        },
        removeNoExistMention(){
            for(let index = 0;index<this.mentionItems.length;index++){
                let checkString = this.mentionItems[index].text.replace("@","@</span>");
                if(this.editContent.includes(checkString)){
                    let mention = {};
                    mention.id = this.mentionItems[index].id;
                    if(mention.id == "all"){
                        mention.class_name = "All";
                    }else{
                        mention.class_name = "User";
                    }
                    this.passMentionItems.push(mention);
                }
            }
        },
        onChangeFile(event) {
            let sum = 0;
            event.forEach(file => {
                sum = sum + file.size;
            });

            if (sum > 20971520) {
                this.isFileAvailable = false;
            } else {
                this.isFileAvailable = true;
            }
        },
    }
}
</script>

<style lang="css">
.newpost{
    width: auto; 
    height: 240px; 
    position: relative; 
    /* background-color:rgb(254, 255, 196); */
    /* border: 3px solid #00bcd4; */
    border-radius: 15px;
    margin: 0 25px;
}
.editPostCss{
    height: auto;
    width: 98%;
    margin-left: 7.5%;
    background-color:#fff;
    border-radius: 15px;
}
.editPostCss .ql-toolbar{
    border-top: 1px solid #518bf2 !important;
    border-right: 1px solid #518bf2 !important;
    border-bottom: 1px solid #CCC !important;
    border-left: 1px solid #518bf2 !important;
    border-radius: 5px 5px 0px 0px;
    padding-bottom : 0px !important;
}
.editPostCss #quill-container{
    border-top: 0px solid #CCC !important;
    border-right: 1px solid #518bf2 !important;
    border-bottom: 1px solid #518bf2 !important;
    border-left: 1px solid #518bf2 !important;
    border-radius: 0px 0px 5px 5px;
    width: 100%;
}
.editPostCss .ql-editor{
    min-height: 190px;
    max-height: 190px;
}
.editPostCss .ql-editor::-webkit-scrollbar {
        height: 8px !important;
        width: 8px !important;
    }
.editPostCss .ql-editor::-webkit-scrollbar-thumb {
        border-radius: 8px !important;
        -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.2) !important;
        background-color: rgba(0,0,0,0.2) !important;
    }
.editPostCss .ql-editor::-webkit-scrollbar-track {
        border-radius:0px !important;
    }

.newreply{
    width: auto; 
    height: 210px; 
    position: relative; 
    border-radius: 15px;
    margin: 0 25px;
}
.editReplyCss{
    height: auto;
    width: 97%;
    margin-left: 8.5%;
    background-color:#fff;
    border-radius: 15px;
}
.editReplyCss #quill-container{
    border: 2px solid #518bf2 !important;
    border-radius: 5px;
    width: 100%;
}
.editReplyCss .ql-editor{
    min-height: 140px;
    max-height: 140px;
}
.editReplyCss .ql-editor::-webkit-scrollbar {
        height: 8px !important;
        width: 8px !important;
    }
.editReplyCss .ql-editor::-webkit-scrollbar-thumb {
        border-radius: 8px !important;
        -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.2) !important;
        background-color: rgba(0,0,0,0.2) !important;
    }
.editReplyCss .ql-editor::-webkit-scrollbar-track {
        border-radius:0px !important;
    }

</style>