<template>
    <!--Post使用 -->
    <div v-if ='ispost' class="newpost">
        <!-- 取消按鈕 -->
        <!-- <div style="position: absolute; top:5px; right: 12px;">
            <v-btn icon style="height: 40px; min-width: 10px; padding: 0 3px;" @click="closereplyPost()">
                <v-icon size='35' color="error" style="background-color:#fff ;border-radius:10px;">mdi-close-circle</v-icon>
            </v-btn>
        </div> -->

        <div style="width: 100%; height: 90%; margin: 5px 0 0 0px;padding:10px 0 10px 0">
            <!-- 頭像 -->
            <v-avatar color='aliceBlue' size='45' style="float: left;margin-left:10px">
                <v-icon v-if="userPhoto == ''">mdi-account</v-icon>
                <img v-else :src="userPhoto" alt="">
            </v-avatar>
            <vue-editor ref='quillEditor' v-model='replyContent' class="replyBorderCss" :editorOptions='editorSettings'></vue-editor>
            <section style="display:flex; align-items:center; justify-content:space-between; max-width: 715px; width: auto; margin-left:60px; margin-top:2%;margin-right: 25px;">
                <section style="display:flex; align-items:center; justify-content:start; width:20%;padding-bottom: 0px;">
                    <div style="position: relative; z-index: 1; display:flex; align-items:center; justify-content:center;">
                        <emoji-picker @emoji="insert" :search="search">
                            <div slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent">
                                <button type="button"><v-icon>mdi-emoticon-happy-outline</v-icon></button>
                            </div>

                            <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                                <div class="emoji-picker">
                                    <div class="emoji-picker__search">
                                        <input type="text" v-model="search">
                                    </div>
                                    <div>
                                        <div v-for="(emojiGroup, category) in emojis" :key="category">
                                            <h5>{{ category }}</h5>
                                            <div class="emojis">
                                                <span
                                                v-for="(emoji, emojiName) in emojiGroup"
                                                :key="emojiName"
                                                @click="insert(emoji)"
                                                :title="emojiName"
                                                >{{ emoji }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </emoji-picker>
                    </div>
                    <div v-if='attachments.length == 0' style="margin-left:20%; min-width:100%;">
                        <v-file-input :label= '$t("CAMPAIGN_DETAIL.ATTACHMENT")' placeholder='' hide-input  dense multiple :rules="formRules.fileRule" v-model='attachments' @change='onChangeFile' v-if='attachments' ></v-file-input>
                    </div>
                    <div v-else style="margin-left:20%; min-width:300%;">
                        <v-row>
                            <v-col style="height:45px;bottom:8px;padding-left:0px">
                                <v-file-input :label= '$t("CAMPAIGN_DETAIL.ATTACHMENT")' placeholder=''  dense multiple :rules="formRules.fileRule" v-model='attachments' @change='onChangeFile' v-if='attachments' ></v-file-input>
                            </v-col>
                        </v-row>
                    </div>
                </section>
                <div style="display:flex; align-items:center; justify-content:space-between;">
                    <div style="margin-right:30px;">
                        <v-btn @click='closereplyPost' width='120%' height='40' color='cancel' depressed dark>{{$t('GENERAL.CANCEL')}}</v-btn>
                    </div>
                    <div style="margin-right:10px;">
                        <v-btn @click='sendToParent' width='120%' height='40' color='success' depressed dark>
                            <v-progress-circular v-if='false' indeterminate size='20' class="t-white"></v-progress-circular>
                            <span>{{$t('INBOX.SEND')}}</span>
                        </v-btn>
                    </div>
                    <!-- <v-btn rounded color="primary" variant="outlined" @click="sendToParent">
                        <v-icon>mdi-send</v-icon>
                        <span style="margin-left:15%;">{{$t("INBOX.SEND")}}</span>
                    </v-btn> -->
                </div>
            </section>
        </div>
    <v-dialog v-model='errorDialog' width='360' content-class='statusDialog' v-if='errorDialog' :attach='true' >
            <error-dialog @emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage'></error-dialog >
    </v-dialog>
    <v-dialog v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog' :attach='true' >
        <message-dialog @emitMessageDialog='onEmitMessageDialog' :message='message'></message-dialog>
    </v-dialog>
    </div>

    <!-- Reply使用 -->
    <div v-else class="newreply">
        <!-- 取消按鈕 -->
        <!-- <div style="position: absolute; top:-2px; right: -15px;">
            <v-btn icon style="height: 40px; min-width: 10px; padding: 0 3px;" @click="closereplyPost()">
                <v-icon size='35' color="error" style="background-color:#fff ;border-radius:10px;">mdi-close-circle</v-icon>
            </v-btn>
        </div> -->

        <div style="width: 100%; height: 90%; margin: 10px 0 0 0;">
            <!-- 頭像 -->
            <v-avatar color='aliceBlue' size='45' style="float: left;margin-left:-10px">
                <v-icon v-if="userPhoto == ''">mdi-account</v-icon>
                <img v-else :src="userPhoto" alt="">
            </v-avatar>
            <vue-editor  ref='quillEditor' v-model='replyContent' class="replyCss" :editorOptions='editorSettings_no_tool'></vue-editor>
            <!-- <section style="display:flex; align-items:center; justify-content:space-between; width:80%; margin-left:10%; margin-top:2%;"> -->
            <section style="display:flex; align-items:center; justify-content:space-between; max-width: 675px; width: auto; margin-left:55px; margin-top:2%;margin-right: 20px;">
                <section style="display:flex; align-items:center; justify-content:start; width:20%">
                    <div style="position: relative; z-index: 99999; display:flex; align-items:center; justify-content:center;">
                        <emoji-picker @emoji="insert" :search="search">
                            <div slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent">
                                <button type="button"><v-icon>mdi-emoticon-happy-outline</v-icon></button>
                            </div>

                            <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                                <div class="emoji-picker">
                                    <div class="emoji-picker__search">
                                        <input type="text" v-model="search">
                                    </div>
                                    <div>
                                        <div v-for="(emojiGroup, category) in emojis" :key="category">
                                            <h5>{{ category }}</h5>
                                            <div class="emojis">
                                                <span
                                                v-for="(emoji, emojiName) in emojiGroup"
                                                :key="emojiName"
                                                @click="insert(emoji)"
                                                :title="emojiName"
                                                >{{ emoji }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </emoji-picker>
                    </div>
                    <!-- for reply attachment(TBD) -->
                    <!-- <div v-if='attachments.length == 0' style="margin-left:-10%; min-width:100%;">
                        <v-file-input :label= '$t("CAMPAIGN_DETAIL.ATTACHMENT")' placeholder='' hide-input  dense multiple :rules="formRules.fileRule" v-model='attachments' @change='onChangeFile' v-if='attachments' ></v-file-input>
                    </div>
                    <div v-else style="margin-left:-10%; min-width:250%;">
                        <v-file-input :label= '$t("CAMPAIGN_DETAIL.ATTACHMENT")' placeholder=''  dense multiple :rules="formRules.fileRule" v-model='attachments' @change='onChangeFile' v-if='attachments' ></v-file-input>
                    </div> -->
                </section>
                <div style="display:flex; align-items:center; justify-content:space-between;">
                    <div style="margin-right:30px;">
                        <v-btn @click='closereplyPost' width='120%' height='40' color='cancel' depressed dark>{{$t('GENERAL.CANCEL')}}</v-btn>
                    </div>
                    <div style="margin-right:10px;">
                        <v-btn @click='sendToParent' width='120%' height='40' color='success' depressed dark>
                            <v-progress-circular v-if='false' indeterminate size='20' class="t-white"></v-progress-circular>
                            <span>{{$t('INBOX.SEND')}}</span>
                        </v-btn>
                    </div>
                    <!-- <v-btn rounded color="primary" variant="outlined" @click="sendToParent">
                        <v-icon>mdi-send</v-icon>
                        <span style="margin-left:15%;">{{$t("INBOX.SEND")}}</span>
                    </v-btn> -->
                </div>
            </section>
        </div>
    <v-dialog v-model='errorDialog' width='360' content-class='statusDialog' v-if='errorDialog' :attach='true' >
            <error-dialog @emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage'></error-dialog >
    </v-dialog>
    <v-dialog v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog' :attach='true' >
            <message-dialog @emitMessageDialog='onEmitMessageDialog' :message='message'></message-dialog>
    </v-dialog>
    </div>

</template>



<script>
import { VueEditor,Quill } from 'vue2-editor';
import {EmojiPicker} from 'vue-emoji-picker';
import Mention from 'quill-mention';
import i18n from '/common/plugins/vue-i18n.js'
import OrganizationDataService from '@/services/OrganizationDataService';
import 'quill-mention/dist/quill.mention.min.css';
import errorDialog from '@/components/Dialog/errorDialog';
import messageDialog from '@/components/Dialog/messageDialog';
// import Emoji from 'quill-emoji/dist/quill-emoji';
// import "quill-emoji/dist/quill-emoji.css";
import ImageResize from "quill-image-resize-vue";

Quill.register({
    'modules/imageResize':ImageResize,
    'modules/mention':Mention,
//     "formats/emoji": Emoji.EmojiBlot,
//     "modules/short_name_emoji": Emoji.ShortNameEmoji,
//     "modules/toolbar_emoji": Emoji.ToolbarEmoji,
//     "modules/textarea_emoji": Emoji.TextAreaEmoji
},true)

async function getMention(searchTerm){
    let list = []
    await OrganizationDataService.getMentionOwner().then((response)=>{
        let userJson = window.localStorage.getItem('user');
        let userId = JSON.parse(userJson).userid;
        list = response.data.map(item => ({
            id :item.id,
            value : item.fullName = item.last_name + ' ' + item.first_name
        })
        )
        list.unshift({id:'all',value: i18n.t('GENERAL.ALL')})
        list = list.filter(el => el.id != userId)
        list = list.filter(el => el.value.includes(searchTerm))
    })
    return list;
}

export default {
    name: 'ReplyEditor',
    props: {
        ownerinfo:{
            require: false,
        },
        ispost:{
            require: false,
        },
    },
    components: {
        VueEditor,
        EmojiPicker,
        errorDialog,
        messageDialog,
    },
    data() {
        return {
            editorSettings_no_tool: {
                modules: {
                    imageResize: true,
                    toolbar:false,
                    // short_name_emoji: true,
                    // toolbar_emoji: true,
                    // textarea_emoji: true,
                    mention:{
                        mentionDenotationChars: ['@'],
                        offsetLeft: 4,
                        source: async function(searchTerm,renderList){
                            const matchedPeople = await getMention(searchTerm);
                            renderList(matchedPeople)
                        },
                        onSelect:(data, insertItem) => {
                            const item = {
                                text: `@${data.value}`,
                                name: data.value,
                                id: data.id,
                            }
                            insertItem(data)
                            this.onSelectd(item)
                        },
                    },
                },
            },
            editorSettings: {
                modules: {
                    imageResize: true,
                    toolbar: {
                        container: [
                            [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                            // ['emoji'],
                            ['bold', 'italic', 'underline'],
                            [{ 'align': '' },{ 'align': 'center' },{ 'align': 'right' }],
                            [{ 'list': 'ordered'}, { 'list': 'bullet' }/*, { 'list': 'check' }*/],
                            [{ 'color': [] }, { 'background': [] }],
                            ['link', 'image'],
                            ['clean']
                            ],
                        handlers: {
                            emoji: function() {}
                        }
                    },
                    // short_name_emoji: true,
                    // toolbar_emoji: true,
                    // textarea_emoji: false,
                    mention:{
                        mentionDenotationChars: ['@'],
                        offsetLeft: 4,
                        source: async function(searchTerm,renderList){
                            const matchedPeople = await getMention(searchTerm);
                            renderList(matchedPeople)
                        },
                        onSelect:(data, insertItem) => {
                            const item = {
                                text: `@${data.value}`,
                                name: data.value,
                                id: data.id,
                            }
                            insertItem(data)
                            this.onSelectd(item)
                        },
                    },
                },
            },
            formRules: {
                    // fileRule: [files => !files || !files.some(file => file.size > 2_097_152) || 'Attachment should be less than 2 MB!'],
                    fileRule: [files => !files || this.isFileAvailable || i18n.t('RULE.RULE_FILE')],
            },
            isFileAvailable:true,
            attachments:[],
            editorOpen:false,
            replyContent:"",
            search:'',
            mentionItems:[],
            passMentionItems:[],
            replyData:{},
            userPhoto: this.getLoginUserPhoto(),
            errorDialog:false,
            errorMessage:'',
            messageDialog: false,
            message: '',
        };
    },
    created (){
    },
    methods: {
        onEmitErrorDialog() {
            this.errorDialog = false;
        },
        onSelectd(item){
            this.mentionItems.push(item);
        },
        insert(emoji) {
            let quill=this.$refs.quillEditor.quill;
            let length = 0;
            try{
                length = quill.getSelection().index;
            }catch{
                length = 0;
            }
            quill.insertEmbed(length,'text',emoji);
        },
        removeNoExistMention(){
            for(let index = 0;index<this.mentionItems.length;index++){
                let checkString = this.mentionItems[index].text.replace("@","@</span>");
                if(this.replyContent.includes(checkString)){
                    let mention = {};
                    mention.id = this.mentionItems[index].id;
                    if(mention.id == "all"){
                        mention.class_name = "All";
                    }else{
                        mention.class_name = "User";
                    }
                    this.passMentionItems.push(mention);
                }
            }
        },
        closereplyPost(){
            if(this.replyContent != "" || this.attachments.length != 0){
                this.showMessageDialog(i18n.t('INBOX.CONTENT_NOT_EMPTY'));
                return;
            }
            else
                this.$emit("close-editor",this.editorOpen)
        },
        showMessageDialog(message) {
            this.messageDialog = true;
            this.message = message;
        },
        onEmitMessageDialog(val) {
            this.messageDialog = false;
            if(val){
                this.$emit("close-editor",this.editorOpen)
            }
        },
        sendToParent(){
            if(!this.isFileAvailable){
                this.errorMessage=i18n.t('INBOX.FILE_LIMIT');
                this.errorDialog=true;
                return;
            }
            this.removeNoExistMention();
            if(this.replyContent == ""||this.replyContent == null){
                this.errorMessage=i18n.t('INBOX.CONTENT_EMPTY');
                this.errorDialog=true;
                this.passMentionItems.length = 0;
                return;
            }
            this.replyData.content = this.replyContent;
            this.replyData.mentioned = this.passMentionItems;
            this.$emit("reply-attachments",this.attachments)
            this.$emit("reply-pass",this.replyData);
        },
        onChangeFile(event) {
            let sum = 0;
            event.forEach(file => {
                sum = sum + file.size;
            });

            if (sum > 20971520) {
                this.isFileAvailable = false;
            } else {
                this.isFileAvailable = true;
            }
        },
        getLoginUserPhoto() {
            var user = window.localStorage.getItem('user');
            let pic = null;
            if (user == null || user == undefined)
                pic = '';
            else {
                let obj = JSON.parse(user);
                pic = obj.pic? obj.pic:'';
            }
            return pic;
        },
    }
}
</script>

<style lang="css">
.newpost{
    background-color: #f3f4fc;
    width: auto;
    height: auto;
    max-height: 290px;
    position: relative;
    /* background-color:rgb(254, 255, 196); */
    /* border: 3px solid #00bcd4; */
    border-radius: 5px;
    margin: -5px 0px;
}
.replyBorderCss{
    height: auto;
    max-width: 715px;
    width: auto;
    /* margin-left: 60px; */
    /* margin-right: 30px; */
    margin: 0px 15px 0px 60px;
    background-color:#fff;
    border-radius: 15px;
}
.replyBorderCss .ql-toolbar{
    border-top: 1px solid #518bf2 !important;
    border-right: 1px solid #518bf2 !important;
    border-bottom: 1px solid #CCC !important;
    border-left: 1px solid #518bf2 !important;
    border-radius: 5px 5px 0px 0px;
    padding-bottom : 0px !important;
    /* overflow-x: scroll; */
    /* white-space:nowrap; */
}
.replyBorderCss #quill-container{
    border-top: 0px solid #CCC !important;
    border-right: 1px solid #518bf2 !important;
    border-bottom: 1px solid #518bf2 !important;
    border-left: 1px solid #518bf2 !important;
    border-radius: 0px 0px 5px 5px;
    width: 100%;
    max-height: 145px;
}
.replyBorderCss .ql-editor{
    min-height: 140px;
    max-height: 140px;
}
.replyBorderCss .ql-editor::-webkit-scrollbar {
        height: 8px !important;
        width: 8px !important;
    }
.replyBorderCss .ql-editor::-webkit-scrollbar-thumb {
        border-radius: 8px !important;
        -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.2) !important;
        background-color: rgba(0,0,0,0.2) !important;
    }
.replyBorderCss .ql-editor::-webkit-scrollbar-track {
        border-radius:0px !important;
    }

.newreply{
    width: auto;
    height: 210px;
    position: relative;
    border-radius: 15px;
    margin: 0 0 0 25px;
}
.replyCss{
    height: auto;
    max-width: 680px;
    width: auto;
    margin-left: 8.5%;
    margin-right: 15px;
    background-color:#fff;
    border-radius: 15px;
}
.replyCss #quill-container{
    border: 1px solid #518bf2 !important;
    border-radius: 5px;
    width: 100%;
}
.replyCss .ql-editor{
    min-height: 140px;
    max-height: 140px;
}
.replyCss .ql-editor::-webkit-scrollbar {
        height: 8px !important;
        width: 8px !important;
    }
.replyCss .ql-editor::-webkit-scrollbar-thumb {
        border-radius: 8px !important;
        -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.2) !important;
        background-color: rgba(0,0,0,0.2) !important;
    }
.replyCss .ql-editor::-webkit-scrollbar-track {
        border-radius:0px !important;
    }
#ql-picker-options-0 {
    z-index: 999;
}

.emoji-invoker {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  padding: 0;
  background: transparent;
  border: 0;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}
.emoji-invoker > svg {
  fill: #b1c6d0;
}
.emoji-picker {
  position: absolute;
  z-index: 999999999;
  font-family: Montserrat;
  border: 1px solid #ccc;
  width: 17rem;
  height: 20rem;
  overflow-y: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 5px;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
  top: -270px;
  left: 40px;
}
.emoji-picker::-webkit-scrollbar {
        height: 8px !important;
        width: 8px !important;
    }
.emoji-picker::-webkit-scrollbar-thumb {
        border-radius: 8px !important;
        -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.2) !important;
        background-color: rgba(0,0,0,0.2) !important;
    }
.emoji-picker::-webkit-scrollbar-track {
        border-radius:0px !important;
    }
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}


</style>