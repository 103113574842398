<template>
    <!-- <div class="bg-alert" @mouseenter="mouseenter" @mouseleave="mouseleave" @click="go_record()"> -->
    <div class="bg-alert" @mouseenter="mouseenter" @mouseleave="mouseleave">
        <v-row :key="alert.id">
            <v-col cols="1" >
                <v-list-item-avatar class="d-inline-block" color="aliceBlue" size="48">
                    <img :src="alert.owner.usersetting.photo">
                </v-list-item-avatar>
            </v-col>
            <v-col cols="11" class='pl-2'>
                <v-list-item class='pl-1 pr-0 pb-0'>
                    <v-list-item-title class='pb-2' style='font-size:18px;font-weight:bold;'>{{ alert.owner_full_name }} </v-list-item-title>
                    <v-icon style="margin:0 25px 0 0; color:#FFA042; font-size:20px;">mdi-bell</v-icon>
                    <v-checkbox class=' ma-0 mt-2' v-model="has_read"  @change="updateStatus()"></v-checkbox>
                </v-list-item>
                <div style='display:flex; align-items:end; justify-content: space-between;'>
                    <v-card-text class='pt-0 pl-1' style='margin-top:-10px;word-break: break-all;'>
                        {{alert.content}}
                    </v-card-text>
                    <v-btn v-if='hover' class="pb-3" icon height='23' color='gary' @click="go_record()">
                        <v-icon>mdi-television-guide</v-icon>
                    </v-btn>
                    <v-btn v-if='hover' class="pb-3" icon height='23' color='gary' @click="onDelete()">
                        <v-icon>mdi-delete-empty</v-icon>
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <!-- <v-checkbox v-model="has_read" style="position:absolute ;right: 25px;top: 7%;"  @change="updateStatus()"></v-checkbox> -->
        <v-divider style='margin-top:4px;margin-bottom:-3px'> </v-divider>
        <!-- <v-hover v-slot="{hover}">
            <v-card  :key="alert.id" style="width: 700px; height: auto;" :elevation="hover ? 12 : 2" :style="{'background-color':hover?'#D0D0D0':'#FFFFFF'}">
                <v-list-item class="w-100">
                    <v-list-item-avatar class="d-inline-block ma-0 mx-4" color="aliceBlue" size="40">
                        <img :src="alert.owner.usersetting.photo">
                    </v-list-item-avatar>
                    <v-list-item-title>{{ alert.owner_full_name }} </v-list-item-title>
                </v-list-item>
                <v-card-text >
                {{alert.content}}
                </v-card-text>
            </v-card>
        </v-hover>
        <v-checkbox v-model="has_read" style="position:absolute ;right: 25px;top: 7%;"  @change="updateStatus()"></v-checkbox> -->
        <v-dialog v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog' :attach='true' >
            <message-dialog @emitMessageDialog='onEmitMessageDialog' :message='message'></message-dialog>
        </v-dialog>
    </div>
</template>

<script>
    import PostDataService from '@/services/PostDataService';
    import i18n from '/common/plugins/vue-i18n.js'; 
    import messageDialog from '@/components/Dialog/messageDialog';

    export default {
        name: 'Alert',
        props: {
            alert:{
                type:Object
            },
            alertIndex:{
                type:Number
            },
        },
        components: {
            messageDialog,
        },
        data() {
            return {
                has_read:false,
                hover:false,
                messageDialog: false,
            };
        },
        created(){
            this.has_read = this.alert.has_read
        },
        watch:{
            alert:{
                handler(){
                    this.has_read = this.alert.has_read;
                },
                deep:true
            }
        },
        methods: {
            go_record(){
                if(this.alert.class_name == "Post"){
                    this.$emit('changeTab',this.alert.class_id)
                }
                else{
                    let path = this.$route.path;
                    let new_path = '';
                    if(this.alert.class_name == "Contact"){
                        new_path = `/Contacts/${this.alert.class_id}`;
                    }
                    if(this.alert.class_name == "Organization"){
                        new_path = `/Account/${this.alert.class_id}`;
                    }
                    if(this.alert.class_name == "Opportunity"){
                        new_path = `/Deal/${this.alert.class_id}`;
                    }
                    if(this.alert.class_name == "Case"){
                        new_path = `/Case/${this.alert.class_id}`;
                    }
                    if(path != new_path && new_path != ""){
                        this.$router.push(new_path);
                        this.$router.go(this.$router.currentRoute);
                    }
                }
            },
            updateStatus(){
                let data={
                    "id" : this.alert.alertregard_id,
                    "has_read": !this.alert.has_read
                }
                PostDataService.updateAlertStatu(data).then(response=>{
                    this.alert.has_read = response.data.has_read;
                    this.$emit("renewUnreadStage","");
                })
            },
            mouseenter(){
                this.hover = true;
            },
            mouseleave(){
                this.hover = false;
            },
            onDelete(){
                this.showMessageDialog(i18n.t('INBOX.DELETE_ALERT'));
            },
            showMessageDialog(message) {
                this.messageDialog = true;
                this.message = message;
            },
            onEmitMessageDialog(val) {
                this.messageDialog = false;
                let data = {};
                data['id'] = this.alert.alertregard_id;
                data['alert_id'] = this.alert.id; 
                data['user_id'] = JSON.parse(window.localStorage.getItem('user')).userid;
                if (val) {
                    PostDataService.deleteAlertregard(data).then(()=>{
                        this.reloadCurrentAlert();
                    });
                }
            },
            reloadCurrentAlert(){
                this.$emit('reloadCurrentAlert',this.alert.alertregard_id,this.alertIndex)
            },
        }
    }
</script>