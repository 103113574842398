<template lang='pug'>
    div
        v-row(:key="replys.id" :id="tab+'_'+replys.id" style='margin-top:-18px;position:relative;' :class='reply_index ? "pt-2":""')
            div.pl-2.pb-1.pt-2.pb-2(style='min-width:62px;max-width:62px;')
                v-list-item-avatar.d-inline-block(color="aliceBlue" size="48")
                        img(:src="replys.owner.usersetting.photo")
            div(style='width:calc(100% - 62px);').pb-1.pl-0.pt-2.pb-2
                v-list-item.pl-1.pr-0.pb-0
                    v-list-item-title.pb-2(style='font-size:18px;font-weight:bold;') {{ replys.owner_full_name }}
                        span.pl-2 ．
                        v-icon(style='padding-bottom:1px' size='18') mdi-clock 
                        span(style='font-size:14px;font-weight:normal ;').pl-1 {{ timeGap }}
                        span(v-if='replys.can_edit').pl-2 ．
                        v-menu(v-if='replys.can_edit' v-model='menu' bottom right z-index='1000000' offset-y)
                            template(v-slot:activator='{ on, attrs }')
                                v-btn(icon @click='openmenu' style='padding-bottom:1px;margin-left:-7px;' )
                                    v-icon(size='20') mdi-dots-vertical
                            v-list
                                v-list-item(v-if='replys.can_edit && !isEdit' @click="onEdit")
                                    v-list-item-title {{$t('GENERAL.EDIT')}}
                                v-list-item(v-if='replys.can_edit' @click="onDelete")
                                    v-list-item-title {{$t('GENERAL.DELETE')}}
                PostEditEditor(v-if="isEdit" :content='editContent' :ispost='false' @onConfirm="onConfirm" @onCancel="onCancel")
                v-card-text.pt-0.pl-2.pb-0(v-else-if='!isEdit && !read_more' style='margin-top:-10px;' ) 
                    vue-editor.can-select-text.v2e_reply_max_height.ellipsis(ref='editor' v-model='replys.content' :disabled='true' :editorOptions='editorSettings')
                    span.read(v-if='replys.over_height' @click='readMore' style="color:#518BF2;cursor: pointer;") ...{{$t('INBOX.READ_MORE')}}
                v-card-text.pt-0.pl-2.pb-0(v-else-if='!isEdit && read_more' style='margin-top:-10px;')
                    vue-editor.can-select-text.v2e_no_border( v-model='replys.content' :disabled='true' :editorOptions='editorSettings')
                    span.read(@click='readMore' style="color:#518BF2;cursor: pointer;") {{$t('INBOX.READ_LESS')}}
            div(v-if='!isEdit' style='position:absolute;right:15px;bottom:2px')
                v-row()
                    v-col(cols='auto')
                        v-btn.pl-0.pb-1(v-if='!reply_index' @click="openReply()" plain style='margin-top:-15px;')
                            v-icon(style='margin-top:10px;') mdi-reply
                    v-col(cols='auto' :class='reply_index? "pl-4 pt-7 pr-3":"pl-0 pr-3"' style='margin-left:-25px;' @mouseenter="mouseenter" @mouseleave="mouseleave")
                        v-menu(v-model='like_menu' top right z-index='1000000' offset-y)
                            template(v-slot:activator='{ on, attrs }')
                                div(style='margin-top:-4px')
                                    span.emoji_small.pl-0.pb-1(v-if='!replys.emoji_id'  @click="like_menu = !like_menu" style='margin-left:8px')
                                        v-icon(size='20' style='margin-top:-2px') mdi-thumb-up
                                    span.emoji_small.pl-0.pb-1(v-else  @click="updateEmoji('delete')" style='padding-top:-10px') {{add_emoji(replys.emoji)}}
                                    span.pl-1(v-if='emojiCount>0' style='padding-top:-px') {{emojiCount}}
                            v-card(style="height: 35px" )
                                v-row(style="height: 30px; margin-top: -9px;" )
                                    v-col(cols=12)
                                        span(@click="updateEmoji('like')").emoji 👍
                                        span(@click="updateEmoji('love')").emoji ❤️
                                        span(@click="updateEmoji('hug')").emoji 🤗
                                        span(@click="updateEmoji('laugh')").emoji 🤣
                                        span(@click="updateEmoji('shock')").emoji 😲
                                        span(@click="updateEmoji('sad')").emoji 😭
                                        span(@click="updateEmoji('angry')").emoji 😡
                        v-menu(v-model='emoji_hover' top right z-index='1000000' offset-y v-if='replys.user_emoji_list.length>0')
                            template(v-slot:activator='{ on, attrs }')
                                div(style='margin-top:-30px;padding-top:30px;')
                            v-card.inbox-list-card(v-if='emoji_hover' @mouseenter="mouseenter_ec" @mouseleave="mouseleave_ec")
                                v-list-item(style="height: auto;min-height:35px;" v-for='member,index in replys.user_emoji_list' :key='index')
                                    v-list-item-avatar.d-inline-block(color="aliceBlue" size="20" )
                                        v-icon(v-if="member.created_by.usersetting.photo == null") mdi-account 
                                        img(v-else :src='member.created_by.usersetting.photo')
                                    v-list-item-title {{ member.created_by.last_name +" " +member.created_by.first_name }}
                                    span.pl-3(style="font-size:20px;") {{add_emoji(member.emoji)}}
                    v-col.pt-0.pb-0(v-if='replys.reply_set.length>0' cols='auto' style='margin-top:8px;margin-left:-8px;padding-right:0;')
                        div(v-if='!isOpen'  @click='openReplys')
                            span(style="color:#518BF2;cursor: pointer;") {{$t('GENERAL.SHOW_CONTENT')}}
                            v-icon(style='cursor: pointer;' color='#518BF2' size='28') mdi-menu-down
                        div(v-if='isOpen'  @click='openReplys')
                            span(style="color:#518BF2;cursor: pointer;") {{$t('GENERAL.HIDE_CONTENT')}}
                            v-icon(style='cursor: pointer;' color='#518BF2' size='28') mdi-menu-up
        v-divider(style='margin-top:12px;margin-bottom:-3px')
        ReplyEditor(v-if="replys.open_reply" @reply-pass="replyPost" @close-editor="closeEditor")
        div.pt-2(v-if='isOpen' v-for="reply in replys.reply_set")
            v-row(align="end" justify="end")
                v-col.pr-0(cols='11')
                    Reply(:tab="tab" :replys="reply" :reply_index='2' @DeletedReplyFromLayer = "DeletedReplyFromSeceondLayer")
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog' attach=true)
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' v-if='errorDialog' attach=true)
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')

</template>
<script>
    import PostEditEditor from './PostEditEditor.vue'
    import ReplyEditor from './ReplyEditor.vue';
    import Reply from './Reply.vue';
    import PostDataService from '@/services/PostDataService';
    import messageDialog from '@/components/Dialog/messageDialog';
    import errorDialog from '@/components/Dialog/errorDialog';
    import { VueEditor } from "vue2-editor";
    import i18n from '/common/plugins/vue-i18n.js';

    export default {
        name: 'Reply',
        props: {
            replys:{
                type:Object,
                require:true,
            },
            reply_index:{
                type:Number,
                require:false,
            },
            tab:{
                type:String
            }
        },
        components: {
            Reply,
            ReplyEditor,
            PostEditEditor,
            VueEditor,
            messageDialog,
            errorDialog,
        },
        data() {
            return {
                emojicard:false,
                emoji_hover:false,
                read_more:false,
                editContent:'',
                isOpen:false,
                timeGap:'',
                message:'',
                messageDialog: false,
                errorMessage:'',
                errorDialog:false,
                emojis:[],
                emojiCount:0,
                isEdit:false,
                menu:false,
                like_menu:false,
                mentioned:[],
                orginal_content:null,
                // vue editor
                editorSettings: {
                    modules: {
                        imageResize: false,
                        toolbar:false
                        },
                },
            };
        },
        created(){
            for(let e in this.replys.all_emoji){
                this.emojis.push(this.add_emoji(this.replys.all_emoji[e].emoji));
                this.emojiCount = this.emojiCount + this.replys.all_emoji[e].count;
            }
            this.getTimeFromNow();
        },
        async mounted(){
            // if(this.$refs.editor.$el.clientHeight>=115 ||this.$refs.editor.$el.innerHTML.includes('data-denotation-char="@"') ){
            //     this.overHeight = true;
            // }
            await this.check_more();
            if(this.replys.over_height == false && this.replys.content.includes('base64')){
                setTimeout(()=>{
                    this.check_more();
                },0)
            }
        },
        methods: {
            mouseenter(){
                this.emoji_hover = true;
            },
            mouseleave(){
                setTimeout(()=>{
                    if(!this.emojicard){
                        this.emoji_hover = false;
                    }
                },100)
            },
            mouseenter_ec(){
                this.emojicard = true;
            },
            mouseleave_ec(){
                if(this.emojicard){
                    this.emoji_hover = false;
                    this.emojicard = false;
                }else{
                    this.emojicard = false;
                }
                
            },
            check_more(val){
                let dom = document.getElementById(this.tab+'_'+this.replys.id);
                let ql_blank = dom.getElementsByClassName('ql-blank');
                let ch = ql_blank[0].clientHeight;
                if(ch >= 70){
                    this.replys.over_height = true;
                }
                else{
                    if(val=="isEdit" && this.replys.over_height==true){
                        this.replys.over_height = false;
                    }
                }
            },
            add_emoji(emoji){
                switch(emoji){
                    case "like":
                        return  "👍";
                    case "love":
                        return  "❤️";
                    case "hug":
                        return  "🤗";
                    case "laugh":
                        return  "🤣";
                    case "shock":
                        return  "😲";
                    case "sad":
                        return  "😭";
                    case "angry":
                        return  "😡";
                    default:
                         return  ""
                }
            },
            readMore(){
                this.read_more = !this.read_more;
            },
            openmenu(){
                this.menu = !this.menu;
            },
            onEdit(){
                this.isEdit = !this.isEdit;
                this.orginal_content = JSON.parse(JSON.stringify(this.replys.content));
                this.editContent = this.replys.content;
            },
            onDelete(){
                this.messageDialog = true;
                this.message = i18n.t('INBOX.DELETE');
            },
            onEmitMessageDialog(val) {
                this.messageDialog = false;
                if (val) {
                    PostDataService.deletePost(this.replys.id).then(()=>{
                        if(this.reply_index){
                            this.$emit("DeletedReplyFromLayer","")
                            return;
                        }
                        this.$emit("DeletedReply","reload")
                    }).catch(()=>{
                        this.errorDialog = true;
                        this.errorMessage = i18n.t('INBOX.ERROR_POST');
                    });
                }
            },
            onEmitErrorDialog(){
                this.errorDialog = false;
            },
            DeletedReplyFromSeceondLayer(){
                this.$emit("DeletedReply","reload")
            },
            onCancel(){
                this.isEdit = false;
                this.replys.content = this.orginal_content;
            },
            async onConfirm(data,attachments){
                this.read_more = false;
                data.id = this.replys.id;
                await PostDataService.updatePost(data).then(response=>{
                    this.replys.content = response.data.content;
                    if(attachments.length > 0){
                        PostDataService.passAttachment(response.data.id,attachments);
                    }
                }).catch(()=>{
                    this.errorDialog = true;
                    this.errorMessage = i18n.t('INBOX.ERROR_POST');
                });
                this.isEdit = false;
                setTimeout(()=>{
                    this.check_more("isEdit");
                },0)
            },
            updateEmoji(emoji){
                let count = false;
                if(this.replys.emoji_id==null){
                    count = true;
                }
                if(emoji == 'delete'){
                    PostDataService.deletePostEmoji(this.replys.emoji_id).then(()=>{
                        this.replys.user_emoji_list = this.replys.user_emoji_list.filter(el => el.id !=this.replys.emoji_id);
                        this.replys.emoji_id = null;
                        this.replys.emoji = null;
                        if(!count){
                            this.emojiCount = this.emojiCount-1;
                        }
                    }).catch(()=>{
                        this.errorDialog = true;
                        this.errorMessage = i18n.t('INBOX.ERROR_POST');
                    });
                    return;
                }
                let data = {
                    "class_name": "Post",
                    "class_id": this.replys.id,
                    "emoji": emoji
                }
                PostDataService.updatePostEmoji(data).then(response=>{
                    this.replys.emoji_id = response.data.emoji.id;
                    this.replys.emoji = response.data.emoji.emoji;
                    this.replys.user_emoji_list = response.data.emoji_list;
                    if(count){
                        this.emojiCount = this.emojiCount+1;
                    }
                }).catch(()=>{
                    this.errorDialog = true;
                    this.errorMessage = i18n.t('INBOX.ERROR_POST');
                });
            },
            openReply(){
                this.replys.open_reply = true;
            },
            openReplys(){
                this.isOpen=!this.isOpen;
            },
            replyPost(val){
                let passData = val;
                passData.type = "POST";
                passData.reply_post = this.replys.id;
                PostDataService.createNewPost(passData).then(()=>{
                    this.$emit("replyAndReloadData","reload");
                }).catch(()=>{
                    this.errorDialog = true;
                    this.errorMessage = i18n.t('INBOX.ERROR_POST');
                });
            },
            closeEditor(){
                this.replys.open_reply = false;
            },
            getTimeFromNow(){
                let timeNow = new Date();
                let minuteCounts =  Math.floor((timeNow-new Date(this.replys.created_on))/1000)/60;
                switch(true){
                    case minuteCounts<=1:{
                        this.timeGap = "<1m"
                        break;
                    }
                    case minuteCounts>1&&minuteCounts<60:{
                        this.timeGap = Math.floor(minuteCounts)+" m"
                        break;
                    }
                    case minuteCounts>=60&&minuteCounts<1440:{
                        this.timeGap = Math.floor(Math.floor(minuteCounts)/60)+" hr"
                        break;
                    }
                    case minuteCounts>=1440:{
                        this.timeGap = (Math.floor(Math.floor(minuteCounts)/1440)).toString()+" d"
                        break;
                    }
                }
            }
        }
    }
</script>