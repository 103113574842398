<template lang='pug'>
    div
        v-row(:key="post.id" :id="tab+'_'+post.id")
            div.pl-3.pb-1.pt-3.pb-2(style='min-width:62px;max-width:62px;')
                v-list-item-avatar.d-inline-block(color="aliceBlue" size="48")
                    img(:src="post.owner.usersetting.photo")
            div(style='width:calc(100% - 66px);').pb-1.pl-1.pt-2.pb-2
                v-list-item.pl-1.pr-0.pb-0
                    v-list-item-title.pb-2(style='font-size:18px;font-weight:bold;') 
                        span(style='padding-top:10px') {{ post.owner_full_name }}
                        span.pl-1 ．
                        //- span(style='font-size:14px; font-weight:normal;').pl-1 {{ createonID }}
                        //- span.pl-1 ．
                        v-icon(style='padding-bottom:1px' size='18') mdi-clock 
                        span(style='font-size:14px;font-weight:normal ;').pl-1 {{ timeGap }}
                        span.pl-1 ．
                        v-menu(v-model='menu' bottom right z-index='1000000' offset-y)
                            template(v-slot:activator='{ on, attrs }')
                                v-btn(icon @click='openmenu' style='padding-bottom:1px;margin-left:-7px;' )
                                    v-icon(size='20') mdi-dots-vertical
                            v-list
                                v-list-item(v-if='post.can_edit && !isEdit' @click="onEdit")
                                    v-list-item-title {{$t('GENERAL.EDIT')}}
                                v-list-item(@click="onSubscribe")
                                    v-list-item-title {{post.subscribe ? $t('INBOX.UNSUBSCRIBE'):$t('INBOX.SUBSCRIBE')}}
                                v-list-item(v-if='post.can_edit' @click="onDelete")
                                    v-list-item-title {{$t('GENERAL.DELETE')}}
                    v-checkbox.ma-0.mt-2(style='padding-right:11px' v-model="has_read"  @change="updateStatus()" )
                PostEditEditor(v-if="isEdit" :content='editContent' :ispost='true' @onConfirm="onConfirm" @onCancel="onCancel")
                v-card-text.pt-0.pl-2.pb-0(v-else-if='!isEdit && !read_more' style='margin-top:-10px;' ) 
                    vue-editor.can-select-text.v2e_no_border.v2e_post_max_height.ellipsis(ref='editor' v-model='post.content' :disabled='true' :editorOptions='editorSettings_no_tool' )
                    span.read(v-if='post.over_height' @click='readMore' style="color:#518BF2;cursor: pointer;") ...{{$t('INBOX.READ_MORE')}}
                v-card-text.pt-0.pl-2.pb-0(v-else-if='!isEdit && read_more' style='margin-top:-10px;')
                    vue-editor.can-select-text.v2e_no_border( v-model='post.content' :disabled='true' :editorOptions='editorSettings_no_tool' )
                    span.read(@click='readMore' style="color:#518BF2;cursor: pointer;") {{$t('INBOX.READ_LESS')}}
        v-card-actions.pb-0(v-if='!isEdit')
            v-row(justify="end")
                v-col.pl-1.pb-0(cols='12')
                    v-row.pt-3(style='margin-left:56px;')
                        v-col.pt-0.pl-0(cols='auto' style='margin-top:5px;margin-bottom:-10px;')
                            v-btn.pl-0.pb-1( @click="openReply()" plain style='margin-top:-7px;margin-left:-5px;')
                                v-icon(size='26') mdi-reply
                        v-col.pt-0.pl-0(cols='auto' :style='post.emoji_id ? "margin-left:-30px;margin-top:5px;margin-bottom:22px;":"margin-left:-30px;margin-top:5px;"' @mouseenter="mouseenter" @mouseleave="mouseleave")
                            v-menu(v-model='like_menu' top right z-index='1000000' offset-y )
                                template(v-slot:activator='{ on, attrs }')
                                    div(style='margin-top:-2px')
                                        v-icon(size='20' style='margin-top:-4px' v-if='!post.emoji_id'  @click="like_menu = !like_menu") mdi-thumb-up
                                        span.emoji_small.pl-0.pb-1(v-else  @click="updateEmoji('delete')" ) {{add_emoji(post.emoji)}}
                                        span.pl-1(v-if='emojiCount>0' ) {{emojiCount}}
                                v-card(style="height: 35px" )
                                    v-row(style="height: 45px; margin-top: -9px;" )
                                        v-col(cols=12)
                                            span(@click="updateEmoji('like')").emoji 👍
                                            span(@click="updateEmoji('love')").emoji ❤️
                                            span(@click="updateEmoji('hug')").emoji 🤗
                                            span(@click="updateEmoji('laugh')").emoji 🤣
                                            span(@click="updateEmoji('shock')").emoji 😲
                                            span(@click="updateEmoji('sad')").emoji 😭
                                            span(@click="updateEmoji('angry')").emoji 😡
                            v-menu(v-model='emoji_hover' top right z-index='1000000' offset-y v-if='post.user_emoji_list.length>0')
                                template(v-slot:activator='{ on, attrs }')
                                    div(style='margin-top:-30px;')
                                v-card.inbox-list-card(v-if='emoji_hover' @mouseenter="mouseenter_ec" @mouseleave="mouseleave_ec")
                                    v-list-item(style="height: auto;min-height:35px;" v-for='member,index in post.user_emoji_list' :key='index')
                                        v-list-item-avatar.d-inline-block(color="aliceBlue" size="20" )
                                            v-icon(v-if="member.created_by.usersetting.photo == null") mdi-account 
                                            img(v-else :src='member.created_by.usersetting.photo')
                                        v-list-item-title {{ member.created_by.last_name +" " +member.created_by.first_name }}
                                        span.pl-3(style="font-size:20px;") {{add_emoji(member.emoji)}}
                        v-col.pt-0(v-if='post.has_seen && post.has_seen.length>0' cols='auto' style='margin-left:-15px;;margin-top:5px;' @mouseenter="mouseenter_seen" @mouseleave="mouseleave_seen")
                            div(style='margin-top:-4px')
                                v-menu(v-model='seen_member' top right z-index='1000000' offset-y )
                                    template(v-slot:activator='{ attrs }')
                                        div(style='margin-top:2px')
                                            v-icon(style='margin-top:-3px').pr-1 mdi-eye-outline
                                            span(style='padding-top:-px').pr-1 {{post.has_seen.length}}
                                v-menu(v-model='seen_hover' top right z-index='1000000' offset-y v-if='post.has_seen.length>0')
                                    template(v-slot:activator='{ on, attrs }')
                                        div(style='margin-top:-30px;')
                                    v-card.inbox-list-card(v-if='seen_hover' @mouseenter="mouseenter_hs" @mouseleave="mouseleave_hs")
                                        v-list-item(v-for="(member,index) in post.has_seen" :key="index" style="height: auto;min-height:35px;" )
                                            v-list-item-avatar.d-inline-block(color="aliceBlue" size="20" )
                                                v-icon(v-if="member.usersetting.photo == null") mdi-account 
                                                img(v-else :src="member.usersetting.photo")
                                            v-list-item-title() {{ member.last_name +" " +member.first_name }}
                        v-col.pt-0(style="padding-left:0px;") 
                            span(class="chip" v-for='key in Object.keys(attachmentObj)')
                                button(@click="downloadFile(key)") {{ key }}
                v-col.pt-0.pb-0.offset-10(v-if='post.reply_set.length>0' cols='auto' style='padding-right:8px;margin-top:-30px')
                    div(v-if='!isOpen'  @click='openReplys')
                        span(style="color:#518BF2;cursor: pointer;") {{$t('GENERAL.SHOW_CONTENT')}}
                        v-icon(style='cursor: pointer;' color='#518BF2' size='28') mdi-menu-down
                    div(v-if='isOpen'  @click='openReplys')
                        span(style="color:#518BF2;cursor: pointer;") {{$t('GENERAL.HIDE_CONTENT')}}
                        v-icon(style='cursor: pointer;' color='#518BF2' size='28') mdi-menu-up
        v-divider(style='margin-bottom:1px')
        ReplyEditor(v-if="post.open_reply" @reply-pass="replyPost" @close-editor="closeEditor")
        div(v-if='isOpen' v-for="reply,index in post.reply_set").bg-post
            v-row(align="end" justify="end").ma-0
                v-col.pr-0(cols='11' :style='index>0 ? "margin-top:-5px":"margin-top:0px"')
                    Reply(:tab="tab" :replys="reply" @replyAndReloadData="reloadCurrentPost" @DeletedReply="reloadCurrentPost")
        v-divider(v-if='isOpen' style='margin-top:0px;margin-bottom:-3px')
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog' attach=true)
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' v-if='errorDialog' attach=true)
            error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
    import PostEditEditor from './PostEditEditor.vue'
    import ReplyEditor from './ReplyEditor.vue'
    import Reply from './Reply.vue'
    import PostDataService from '@/services/PostDataService';
    import messageDialog from '@/components/Dialog/messageDialog';
    import errorDialog from '@/components/Dialog/errorDialog';
    import { VueEditor } from "vue2-editor";
    import i18n from '/common/plugins/vue-i18n.js';

    export default {
        name: 'Post',
        props: {
            post:{
                type:Object
            },
            postIndex:{
                type:Number
            },
            tab:{
                type:String
            }
        },
        components: {
            Reply,
            ReplyEditor,
            PostEditEditor,
            VueEditor,
            messageDialog,
            errorDialog,
        },
        data() {
            return {
                read_more:false,
                editContent:'',
                isOpen:false,
                timeGap:'',
                message:'',
                messageDialog: false,
                errorMessage:'',
                errorDialog:false,
                like_menu:false,
                seen_member:false,
                has_seenMore:false,
                menu:false,
                isEdit:false,
                attachmentObj:{},
                // vue editor
                editorSettings_no_tool: {
                    modules: {
                        imageResize: false,
                        toolbar:false
                        },
                },
                emojis:[],
                emojiCount:0,
                has_read:null,
                orginal_content:null,
                mentioned:[],
                createonID:"",
                emoji_hover:false,
                emojicard:false,
                seen_hover:false,
                seencard:false,
            };
        },
        created(){
            this.has_read = this.post.has_read;
            for(let e in this.post.all_emoji){
                this.emojis.push(this.add_emoji(this.post.all_emoji[e].emoji));
                this.emojiCount = this.emojiCount + this.post.all_emoji[e].count;
            }
            // this.generalID();
            this.getTimeFromNow();
            this.getAttachmentObj();
        },
        watch:{
            post:{
                handler(){
                    this.has_read = this.post.has_read;
                    this.getAttachmentObj();
                },
                deep:true
            }
        },
        async mounted(){
            // if(this.$refs.editor.$el.clientHeight>=115 ||this.$refs.editor.$el.innerHTML.includes('data-denotation-char="@"') ){
            //     this.overHeight = true;
            // }
            await this.check_more();
            if(this.post.over_height == false && this.post.content.includes('base64')){
                setTimeout(()=>{
                    this.check_more();
                },0)
            }
        },
        methods: {
            mouseenter_seen(){
                this.seen_hover = true;
            },
            mouseleave_seen(){
                setTimeout(()=>{
                    if(!this.seencard){
                        this.seen_hover = false;
                    }
                },100)
            },
            mouseenter_hs(){
                this.seencard = true;
            },
            mouseleave_hs(){
                if(this.seencard){
                    this.seen_hover = false;
                    this.seencard = false;
                }else{
                    this.seencard = false;
                }
                
            },
            mouseenter(){
                this.emoji_hover = true;
            },
            mouseleave(){
                setTimeout(()=>{
                    if(!this.emojicard){
                        this.emoji_hover = false;
                    }
                },100)
            },
            mouseenter_ec(){
                this.emojicard = true;
            },
            mouseleave_ec(){
                if(this.emojicard){
                    this.emoji_hover = false;
                    this.emojicard = false;
                }else{
                    this.emojicard = false;
                }
                
            },
            check_more(val){
                let dom = document.getElementById(this.tab+"_"+this.post.id);
                let ql_blank = dom.getElementsByClassName('ql-blank');
                let ch = ql_blank[0].clientHeight;
                if(ch >= 115){
                    this.post.over_height = true;
                }
                else{
                    if(val=="isEdit" && this.post.over_height==true){
                        this.post.over_height = false;
                    }
                }
            },
            add_emoji(emoji){
                switch(emoji){
                    case "like":
                        return  "👍";
                    case "love":
                        return  "❤️";
                    case "hug":
                        return  "🤗";
                    case "laugh":
                        return  "🤣";
                    case "shock":
                        return  "😲";
                    case "sad":
                        return  "😭";
                    case "angry":
                        return  "😡";
                    default:
                         return  ""
                }
            },
            readMore(){
                this.read_more = !this.read_more;
            },
            openmenu(){
                this.menu = !this.menu;
            },
            openSeenMember(){
                this.seen_member = !this.seen_member;
            },
            getAllSeen(){
                this.has_seenMore = true;
            },
            onEdit(){
                this.isEdit = !this.isEdit;
                this.orginal_content = JSON.parse(JSON.stringify(this.post.content));
                this.editContent = this.post.content;
            },
            onSubscribe(){
                let data={
                    "id" : this.post.regard_id,
                    "has_read": this.post.has_read,
                    "subscribe": !this.post.subscribe
                }
                PostDataService.updatePostStatu(data).then(response=>{
                    this.post.subscribe = response.data.subscribe;
                }).catch(()=>{
                    this.errorDialog = true;
                    this.errorMessage = i18n.t('INBOX.ERROR_POST');
                });
            },
            onDelete(){
                this.messageDialog = true;
                this.message = i18n.t('INBOX.DELETE');
            },
            onEmitMessageDialog(val) {
                this.messageDialog = false;
                if (val) {
                    PostDataService.deletePost(this.post.id).then(()=>{
                        this.reloadCurrentPost("delete");
                    });
                }
            },
            onEmitErrorDialog(){
                this.errorDialog = false;
            },
            onCancel(){
                this.isEdit = false;
                this.post.content = this.orginal_content;
            },
            async onConfirm(data,attachments){
                this.read_more = false;
                data.id = this.post.id;
                await PostDataService.updatePost(data).then(response=>{
                    this.post.content = response.data.content;
                    if(attachments.length > 0){
                        let attachmentCounts = attachments.length;
                        attachments.forEach((item,index)=>{
                            PostDataService.passAttachment(response.data.id,item).then(()=>{
                                if(index == (attachmentCounts-1))this.reloadCurrentPost();
                            });
                        })
                    }
                }).catch(()=>{
                    this.errorDialog = true;
                    this.errorMessage = i18n.t('INBOX.ERROR_POST');
                });

                this.isEdit = false;
                setTimeout(()=>{
                    this.check_more("isEdit");
                },0)
            },
            updateEmoji(emoji){
                let count = false;
                if(this.post.emoji_id==null){
                    count = true;
                }
                if(emoji == 'delete'){
                    PostDataService.deletePostEmoji(this.post.emoji_id).then(()=>{
                        this.post.user_emoji_list = this.post.user_emoji_list.filter(el => el.id !=this.post.emoji_id);
                        this.post.emoji_id = null;
                        this.post.emoji = null;
                        if(!count){
                            this.emojiCount = this.emojiCount-1;
                        }
                    }).catch(()=>{
                        this.errorDialog = true;
                        this.errorMessage = i18n.t('INBOX.ERROR_POST');
                    });
                    return;
                }
                let data = {
                    "class_name": "Post",
                    "class_id": this.post.id,
                    "emoji": emoji
                }
                PostDataService.updatePostEmoji(data).then(response=>{
                    this.post.emoji_id = response.data.emoji.id;
                    this.post.emoji = response.data.emoji.emoji;
                    this.post.user_emoji_list = response.data.emoji_list;
                    if(count){
                        this.emojiCount = this.emojiCount+1;
                    }
                }).catch(()=>{
                    this.errorDialog = true;
                    this.errorMessage = i18n.t('INBOX.ERROR_POST');
                });
            },
            openReply(){
                this.post.open_reply=true;
            },
            openReplys(){
                this.isOpen=!this.isOpen;
            },
            replyPost(val){
                let passData = val;
                passData.type = "POST";
                passData.reply_post = this.post.id;
                PostDataService.createNewPost(passData).then(()=>{
                    this.reloadCurrentPost();
                }).catch(()=>{
                    this.errorDialog = true;
                    this.errorMessage = i18n.t('INBOX.ERROR_POST');
                });
            },
            reloadCurrentPost(actionType = "reload"){
                this.$emit('reloadCurrentPost',this.post.id,this.postIndex,actionType)
            },
            updateStatus(){
                let data={
                    "id" : this.post.regard_id,
                    "has_read": !this.post.has_read,
                    "subscribe": this.post.subscribe
                }
                PostDataService.updatePostStatu(data).then(response=>{
                    if(response.data.has_read){
                        this.post.has_seen.push(response.data.user);
                    }else{
                        this.post.has_seen = this.post.has_seen.filter(el=>el.id != response.data.user.id);
                    }
                    this.post.has_read = response.data.has_read;
                    this.$emit("renewUnreadStage","");
                }).catch(()=>{
                    this.errorDialog = true;
                    this.errorMessage = i18n.t('INBOX.ERROR_POST');
                });
            },
            closeEditor(){
                this.post.open_reply = false;
            },
            // generalID(){
            //     let createondata = this.post.created_on;
            //     let YY = createondata.slice(0,4);
            //     let MM = createondata.slice(5,7);
            //     let DD = createondata.slice(8,10);
            //     let hh = createondata.slice(11,13);
            //     let mm = createondata.slice(14,16);
            //     let ss = createondata.slice(17,19);
            //     let temp = YY + '.' + MM + '.' + DD + ' - ' + hh + mm + ss;
            //     // temp = parseInt(temp).toString(16).toUpperCase().split("").reverse().join("");
            //     this.createonID = temp;
            //     this.post.createonID = temp;
            // },
            getTimeFromNow(){
                let timeNow = new Date();
                let minuteCounts =  Math.floor((timeNow-new Date(this.post.created_on))/1000)/60;
                switch(true){
                    case minuteCounts<=1:{
                        this.timeGap = "<1m"
                        break;
                    }
                    case minuteCounts>1&&minuteCounts<60:{
                        this.timeGap = Math.floor(minuteCounts)+" m"
                        break;
                    }
                    case minuteCounts>=60&&minuteCounts<1440:{
                        this.timeGap = Math.floor(Math.floor(minuteCounts)/60)+" hr"
                        break;
                    }
                    case minuteCounts>=1440:{
                        this.timeGap = (Math.floor(Math.floor(minuteCounts)/1440)).toString()+" d"
                        break;
                    }
                }
            },
            downloadFile(key){
                const download = document.createElement('a');
                download.href = this.attachmentObj[key]+'&response-content-disposition=attachment';
                download.target="_blank";
                download.download = "";
                document.body.appendChild(download);
                download.click();
                document.body.removeChild(download);
            },
            getAttachmentObj(){
                if(this.post.attachment){
                    this.post.attachment.forEach(element => {
                        let strArray = this.getFileName(element).split('.');
                        let strLength = strArray.length;
                        let fileName;
                        (strArray[0].substring(0,7) == strArray[0] && strLength == 2)?fileName = this.getFileName(element):fileName = strArray[0].substring(0,7) + '…' +'.' + strArray[(strLength - 1)];
                        this.attachmentObj[fileName]=element;
                    });
                }
            },
            getFileName(filePath) {
                filePath = filePath.split("attachment/")
                return decodeURI(filePath[1].replace(/^.*[\\/]/, '').split('?')[0]);
            },
        }
    }
</script>

<style lang="scss">
#no-background-hover::before {
   background-color: transparent !important;
}
.emoji{
    font-size:20px;
    cursor: pointer;
}
.emoji_small{
    font-size:16px;
    cursor: pointer;
}
.chip {
  display: inline-block;
  padding: 0 25px;
  height: 25px;
  font-size: 16px;
  line-height: 25px;
  border-radius: 25px;
  background-color: #f1f1f1;
  transition: 0.3s;
}
.chip:hover{
    margin-bottom: 5px;
    box-shadow: 0 3px 15px 3px rgba(74, 74, 74, 0.5);
    background-color: #d8d8d8;
}

</style>
